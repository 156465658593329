@import '../../../styles/color';
@import '../../../styles/variables';
@import "node_modules/bootstrap/scss/mixins";



.case {

  p {
    color: $primary;
  }


  h1 {
    font-size: 4.2vw;
    padding-bottom: 1rem;
  }

  @include media-breakpoint-down(md) {

    h1 {
      font-size: 2rem;
    }
  }


  .bubble {
    position: absolute;

    border-radius: 50%;
    background-color: rgb(0, 51, 199);
    box-shadow: -3.583px 12.496px 35px 0px rgba(0, 0, 0, 0.53);
    width: 150px;
    height: 150px;

    .content {

      padding-top: 15px;

      h5 {
        font-family: "PT Sans";
        font-weight: 700;
        color: white;
        margin-bottom: -2px;

      }

      p {
        font-weight: 700;
        color: white;
        text-transform: uppercase;
        font-size: .7rem;
      }

    }

  }

  .images {
    background-image: url("../../../assets/case-image-grid.png");
    background-repeat: no-repeat;
    background-size: cover;
  }

}
