@import '../../styles/color';
@import '../../styles/variables';
@import "node_modules/bootstrap/scss/mixins";

.infocard {

  color:$primary;

  // we do have media query for this in generic-page
  //
  //height:auto;

  .side-txt {

    .side-txt-row {
      padding-top: 15px;

      .ex-content {

        @include media-breakpoint-down(md) {

          height: 0;


        }

      }



      @include media-breakpoint-down(md) {
        /*padding-left: 50px;
        padding-right: 50px;
        padding-top: 10px;
        padding-bottom: 10px;*/

        /*.col-12 {
          padding-left: 50px;
          padding-left: 50px;
        }*/

      }

    }



    .side-txt-left {
      .col-12 {
        //padding-left: 50px;
      }
    }


    .side-txt-right {
      .col-12 {
        //padding-right: 50px;
      }
    }




  }


}

