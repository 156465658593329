.the-colab{

    background:#fddb4c;
    box-shadow: -3px 12px 35px 0px rgba(0, 0, 0, 0.3  );

    .square {
        position: relative;
        width: 80%;
    }

    .square:after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }

    .content {

        position: absolute;
        width: 100%;
        height: 100%;

        .logo{
            background: white;
            border-radius: 50%;
            padding: 20px;

            .firstmove-logo{
                /*background: white;
                border-radius:50% ;*/
                display: block;
                width: 100%;
                height: 100%;
                background: url("../../assets/svg/firstmoveLogo.svg");
                background-position:50% 50%;
                background-repeat:no-repeat;
                background-size: contain;


            }




        }


/*        background: url("../../assets/svg/firstmoveLogo.svg");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;*/
    }



}
