
.btn.btn-primary {
  border-radius: 0;
  color: $primary;
  background: white;
  font-size: .8rem;
}


h1{

  font-family: 'Playfair Display';
  color:$primary;

}