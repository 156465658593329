@import '../../styles/mixins';
@import '../../styles/variables';

//@import '../../styles/color';
@import "node_modules/bootstrap/scss/mixins";


.tool {

 /* .page-headline{
    @include my-page-headline(white);
  }
*/
  .tool-image-container{

     /*height:100%;
     width:100%;*/

      img {

        max-width:100%;

      }

    /*.tool-image{

      background-image: url("../../assets/tool.png");
      background-repeat: no-repeat;
      background-position: bottom;
      //background-size: 1103px 612px;
      background-size: contain;

      min-height:100% ;
      //width:100%;


    }*/
  }




}

