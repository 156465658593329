@import '../../styles/variables';
@import '../../styles/color';
@import "node_modules/bootstrap/scss/mixins";

$head-btn-y-padding: 20px;
$head-btn-x-padding: 60px;

.front {



    padding-top: 15px !important;

    .dot-pattern {
        position: absolute;
        top: 50px;
        right: 50px;
        background-image: url("../../assets/dot-pattern.png");

        z-index: 1000;
        width: 150px;
        height: 500px;

        @include media-breakpoint-down(sm) {
            width: 60px;
            height: 100px;
        }
    }



    /*.v-header{

        height: 100vh;
        //display: flex;
        //align-items: center;

    }*/




    .video-wrap {

        position: absolute;
        top: 0;
        left: 0;
        //width: calc(100% - 15px);
        width: 100%;
        height: 100vh;
        overflow: hidden;

        padding-bottom: 36px;

        //margin-right: -30px;
        //padding-right: 60px;




        video {
            min-width: 100%;
            min-height: 100%;
            box-shadow: -3px 12px 35px 0px rgba(0, 0, 0, 0.5  );
            //opacity: .5;
        }


    }


    /*
    .video-wrap:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;
        height: 100%;
        width: 100%;
        background: rgba(0, 51, 199, .3);
    }
    */



    .front-background {
        background-image: url('../../assets/svg/bigSwirl_min.svg');
        background-position: 80% 300%;
        background-size: auto 80%;
        background-repeat: no-repeat;
    }




    .headline {
        //color: $primary;

        display: -webkit-flex;
        display: flex;

       -webkit-align-items: center;
        align-items: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;

        position: relative;
        z-index: 100;


        p {
            //color: $primary;
            //color: white;
            color: $primary;
        }

        h1 {
            //color: white;
            color: $primary;
            font-size: 4.2vw;
            padding-bottom: 1rem;
        }

        @include media-breakpoint-down(md) {

            h1 {
                font-size: 2rem;
            }

            h2 {
                font-size: 2rem;
            }
        }
    }

    .front-content {

        .slide-background {
            position: absolute;
            height: auto;
            top: 0;
            left: 0;
            bottom: 0;

            .sliding-image {
                background: url("../../assets/mobile.png") repeat-x;
                background-position: 100% 100%;
                width: 5076px;
                height: 100%;
                animation: slideHoz 15s linear infinite;
            }

        }

        .txt-content {

            width: 100%;

            @include media-breakpoint-down(xl) {
                width: 70%;
            }

            @include media-breakpoint-down(lg) {
                width: 50%;
            }

            @include media-breakpoint-down(md) {
                width: 70%;
                padding-left: 5%;
            }

            @include media-breakpoint-down(sm) {
                width: 100%;
                padding-left: 5%;
            }

        }


        @keyframes slideHoz {
            0% {
                transform: translate3d(0, 0, 0);
            }
            100% {
                transform: translate3d(-1692px, 0, 0);
                //transform: translate3d(50px, -321px, 0);
            }
        }

    }

}
