/*
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;*/
/*
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;*/
/*
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;*/
/*
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;*/
.influencer-testimoniel {
  min-height: 100px;
  /*@include media-breakpoint-down(md) {
        min-height: 50%;
    }*/
  /*


    @include media-breakpoint-down(md) {
        height: 50%;
    }


    @include media-breakpoint-down(sm) {
        height: 25%;
    }
    */ }

@media (min-width: 576px) and (max-height: 790px) {
  .influencer-testimoniel .content {
    height: 600px; } }
  .influencer-testimoniel .user-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("../../assets/influencers/influencerQuoteImage.png"); }
  .influencer-testimoniel .myquote {
    text-indent: 40px; }
    .influencer-testimoniel .myquote svg g {
      fill: chocolate; }
  .influencer-testimoniel .myquote:before {
    content: url("../../assets/svg/quote.svg");
    position: absolute;
    width: 30px;
    height: 30px;
    top: 0;
    left: -40px; }
