@import '../../styles/color';
@import '../../styles/variables';
@import "node_modules/bootstrap/scss/mixins";


.app-menu-overlay {


  background: rgba(0, 0, 0, .85);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  right: 0;
  z-index: 200;


  .menu-items {

    @include media-breakpoint-up (md) {
      margin-top:100px;

    }



    .menu-item {
      cursor: pointer;
      text-align: left;

      position: relative;

     /* span{
        position: absolute;
        top:50%;
        left:0px;
        width:60px;
        height: 2px;
        background: white;
        opacity: 0;
      }*/


      .lineLeft{

        @include media-breakpoint-down (md) {
          width:15%;

        }

        position: absolute;
        top:50%;
        left:0;
        width:40px;
        height: 2px;
        background: white;
        opacity: 0;


      }

      .lineRight {

        @include media-breakpoint-down (md) {
          width:15%;
        }

        position: absolute;
        top:50%;
        right:0;
        width:0%;
        height: 2px;
        background: white;
        opacity: 0;

        transition: opacity 0.5s ease-out;
      }


      a {

        &.active  {

          //position: relative;

          .lineLeft {
            opacity: 1;

          }

          .lineRight {
            opacity: 1;

          }

          h1 {

            //background: rgba(255,255,255,.2);
            //color: white;
          }
        }
      }

      a:hover{
        color:white;
        height:1px;
      }

      h1 {
        font-size: 4vw;
        color: white;
        padding: 4px;
        margin-left: 5%;
      }

      @include media-breakpoint-down(md) {
        text-align: center;
        h1 {
          font-size: 40px;
          color: white;
          padding: 20px;
          margin-left: 0;
        }

      }
    }


  }


}
