@import "../../styles/color";

.top-navigation {

  position: absolute;
  z-index: 500;
  margin-top:25px;


  .nav-item{
    font-weight: 400;
    color:$primary;
    letter-spacing: 1.5px;
    font-size: 12px;
    text-transform: uppercase;
    //padding-right: 10px;
  }
}
