@import '../../styles/color';

.front-items {



    .item{


        p{
            color:#d3d9e8;;
            margin:0;
            font-size:12px;
        }
        h6{
            color:white;
            margin:0;
            font-size:12px;
        }

        .insta-logo{
            g {
                fill: #d3d9e8;
            }
        }


        .item-inner {

            //overflow: hidden;

            //background-size:contain;
            background-size:cover;
            //background-position: center center;
            //background-repeat: no-repeat;

            border-radius: 8px;
            box-shadow: -3px 8px 30px 0px rgba(0, 0, 0, 0.2 );

            //background: linear-gradient(190deg, rgba(0,0,0,0) 60%, rgba(0,0,0,.75) 80%);

            .item-txt{
                border-radius: 8px;
                background: linear-gradient(190deg, rgba(0,0,0,0) 60%, rgba(0,0,0,.75) 80%);
                //padding-left: 10px;
                //padding-bottom: 5px;
            }
        }


        //transform: all 150ms ease-out;

    }

    /*.item-inner:hover{

        background: green;
        outline:10px solid $primary;
    }*/



}
