/*
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;*/
/*
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;*/
.run-campaigns {
  height: 100%; }
  .run-campaigns .steps .step {
    /*  &.order-0 {
                border-right: none;
            }

            &.order-1 {
                border-left: none;
            }


            &.txt-con{
                border-right: none;

            }

            &.img-con{
                border-left: none;
            }
*/ }
    @media (min-width: 992px) {
      .run-campaigns .steps .step .step-item {
        padding: 100px;
        /*padding-top: 100px;
                    padding-bottom: 100px;
                    padding-left: 50px;
                    padding-right: 10px;*/ } }
    @media (max-width: 991.98px) {
      .run-campaigns .steps .step .step-item {
        padding: 50px; } }
    @media (max-width: 767.98px) {
      .run-campaigns .steps .step .step-item {
        padding: 0px 30px 10px 30px; } }
    .run-campaigns .steps .step .step-item h4 {
      color: #0033c7; }
    .run-campaigns .steps .step .step-item .step-label {
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 700; }
