@import '../../styles/color';
@import '../../styles/variables';
@import "node_modules/bootstrap/scss/mixins";


.run-campaigns {


    //height:auto;
    height:100%;

    //margin-bottom:50px;

    .steps {



        .step {

            @include media-breakpoint-up(lg) {
                $stepBorder: 5px;
                //border: $stepBorder solid $primary;
            }


          /*  &.order-0 {
                border-right: none;
            }

            &.order-1 {
                border-left: none;
            }


            &.txt-con{
                border-right: none;

            }

            &.img-con{
                border-left: none;
            }
*/

            .step-item {



                @include media-breakpoint-up(lg) {

                    padding: 100px;
                    /*padding-top: 100px;
                    padding-bottom: 100px;
                    padding-left: 50px;
                    padding-right: 10px;*/

                }


                @include media-breakpoint-down(md) {
                    padding: 50px;
                }

                @include media-breakpoint-down(sm) {

                    padding: 0px 30px 10px 30px;
                }


                h4 {
                    color: $primary;
                }

                .step-label {
                    margin-bottom: 0;
                    font-size: 14px;
                    font-weight: 700;
                }

            }
        }


    }





}
