@import '../../styles/color';
@import '../../styles/variables';
@import "node_modules/bootstrap/scss/mixins";


.interest-graph {



    .head-txt{

        b{
            font-weight: bold;
        }

    }

    .interest-mobile{

        h5{
            color:$primary
        }


        p{
            color:$primary
        }

    }



    .pixi-container {
        //display: flex;
        //flex:1;

        //height:auto;
        //height:100%;

        @include media-breakpoint-up(lg) {
            //height: 20rem;
            height: auto;
        }


        @include media-breakpoint-down(md) {
            height: 20rem;
        }

        @include media-breakpoint-down(sm) {
            height: 18rem;
        }
        @include media-breakpoint-down(xs) {
            height: 11rem;
        }


        //background: chartreuse;
    }


}


.pixi-element {

    @include media-breakpoint-down(md) {
        pointer-events: none;
    }

}
