
@import '../../styles/mixins';
//@import "../../styles/styles";
@import '../../styles/color';

.page-footer{

    height:auto;

    p{
        font-size: .8rem;
        color:white;
    }
    /*background-color: $offwhite;

    padding-top: 1rem !important;
    padding-bottom:1rem ;
    padding-left:0 !important;;
    padding-right:0 !important;*/


}
