
.side-menu-component {

    .side-menu {

        background-color: white;
        border-right: #c6cfed 1px solid;
        box-shadow: 0px 0px 40px -5px rgba(0, 0, 0, .2); //,0px 0px 40px -5px rgba(0,0,0,.2);

        .social-icons {            margin-bottom: 20px;

            img {
                max-width: 25px;
            }
        }

    }

}

