@import '../../styles/mixins';
//@import "../../styles/styles";
@import '../../styles/color';
//@import "node_modules/bootstrap/scss/mixins";

.generic-page {

  //position:relative; // to help the navigation

  height: 100%;

  p, h5 {
    color: $primary;
  }


  @media (max-height: 790px) {
    //background: red;
    height: auto;
    min-height: 790px;
    //margin-bottom: 120px;

  }


  @include media-breakpoint-down(md) {

    height: auto;
  }


  .section-top{
    height: 100%;
  }


  padding-top: 15px !important;
  padding-bottom:1rem ;
  padding-left:0 !important;;
  padding-right:0 !important;


  .offwhite{
    background-color: $offwhite;
    box-shadow: -3px 12px 35px 0px rgba(0, 0, 0, 0.5  );
  }


  .page-headline-white{
    @include my-page-headline(white);
  }

  .page-headline{
    @include my-page-headline($primary);
  }

  .page-headline-left{
    @include my-page-headline($primary, left);
  }


}
