
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/color';
@import "node_modules/bootstrap/scss/mixins";


.branded-content{


    .big-image{

        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url("../../assets/influencers/brandedContent.jpg");

    }



    .my-headline{


        @include my-page-headline($primary, left);

        @include media-breakpoint-down(md) {
            @include my-page-headline($primary, center);

        }





    }


    p{
        color:$primary;
    }

    h6{
        color:$primary;
    }


    .item{

        p {
            color: $primary;
        }
        .item-inner{
            position: relative;
            z-index: 1;

            .mySvg {
                g {
                    fill: $primary;
                }
            }
        }

        .item-inner::after{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            border-style: solid;
            border-width: 1px;
            border-color: rgba(0, 0, 0, .2);
            background-color: rgb(255, 255, 255);
            box-shadow: 0px 11px 46px 0px rgba(9, 28, 83, 0.15);
        }
    }


}
