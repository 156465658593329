.testimonial {




  .testimonial-image{

      background-image: url("../../assets/testimoniels.jpg");
      background-repeat: no-repeat;
      background-position: center;
      //background-size: 1103px 612px;
      background-size: cover;

      min-height:300px;
      width:100%;

  }

  .txt{

    padding-left: 3rem;
    padding-right: 3rem;
    background: #f6b774;
    color: #473026;

    img{
      max-width:50%;
    }

  }


}
