@import '../../styles/color';
@import '../../styles/variables';
@import "node_modules/bootstrap/scss/mixins";

@mixin back-icon($url) {

    :before {

        content: "";

        position: absolute;
        width: 60%;
        height: 60%;
        top: -10px;
        left: -40px;
        z-index: 1;
        opacity: .035;
        background: url($url);
        background-position: 0px -10px;
        background-repeat: no-repeat;
        transform: rotate(15deg);

    }

}



.get-paid {



    .myimg{
        background-size: auto 60%;
        background-repeat: no-repeat;
        background-position: top center;
        background-image: url("../../assets/influencers/getPaid.png");
    }

    .item {

        padding-left: 2px;
        padding-right: 2px;
        position: relative;
        z-index: 20;

        @include media-breakpoint-up(lg) {
            padding-left: 15px;
            padding-right: 15px;
        }

        p {
            color: white;
        }


        .item-inner {

            position: relative;
            background: $primary;
            z-index: 20;

            h6 {
                color: white;
            }


        }


        .item-1{
            position: relative;
            @include back-icon("../../assets/svg/signup.svg");
        }


        .item-2{
            @include back-icon("../../assets/svg/seen.svg");
        }

        .item-3{
            @include back-icon("../../assets/svg/create.svg");
        }

        .item-4{
            position: relative;
            @include back-icon("../../assets/svg/value.svg");
        }


        .item-inner::after{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            box-shadow: 0px 11px 46px 0px rgba(9, 28, 83, 0.5);
        }
    }


}
