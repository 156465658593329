@import './color';


html {
  font-size: 1rem;

  p{
    color:$primary;
  }

  h1, h2, h3, h4, h5, h6{
    color:$primary;
  }

}


@include media-breakpoint-up(sm) {
  html {
    font-size: 1rem;
  }


}

@include media-breakpoint-up(md) {
  html {
    font-size: 1.2rem;
  }
}

@include media-breakpoint-up(lg) {
  html {
    font-size: 1.4rem;

    p{
      font-size: .8rem;
    }

  }
}

@include media-breakpoint-up(xl) {
  html {
    font-size: 1.3rem;

    p{
      font-size: .8rem;
    }

  }
}
