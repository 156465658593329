

@import '../../styles/mixins';
@import '../../styles/variables';
@import '../../styles/color';


.brands {

    .section-headline{

        @include my-section-headline();
        max-width: 800px;

    }

    .quotebox{
        box-shadow: -3px 11px 35px 0px rgba(0, 0, 0, .3);
    }


    .top-numbers{
        .top-numbers-value{

            border-bottom: 2px solid $primary;

            h2{
                margin-bottom: 0;
                font-weight: 900;
            }

            .number-procent{
                font-size: 1.2rem;
            }

        }

        .top-numbers-title{

        }
    }

    .kirsten-quote-text{

        padding-top:10px;
        padding-left: 10px ;
        i{

            font-size: 1.1rem;
        }

        @include media-breakpoint-down(sm) {

            padding-left: 0px ;
                i{

                }


        }

    }



/*  .page-headline{
    @include my-page-headline(white);
  }*/


/*  .head-image {

    //width:291px;
    background: url('../../assets/brands/header-image.jpg') ;
    background-repeat: no-repeat;
    background-position: right;
    background-size: 291px 100%;
    //background-size: cover; //auto 100%;
    //background-size: cover;


  }*/

}
