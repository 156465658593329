@import '../../../styles/mixins';
@import '../../../styles/color';

@import "node_modules/bootstrap/scss/mixins";

.firstmovers {



    .dot-pattern{
        position: absolute;
        top:0;
        left:0;
        background-image:url("../../../assets/dot-pattern.png");

        width:150px;
        height:200px;

        @include media-breakpoint-down(sm) {
            width:60px;
            height:100px;
            //background: red;
            //background-size: 50% 50% ;
            //background-size: 50% 50% ;
            //width:10px;
            //height:auto;

        }


    }


    .featured {

        .item-inner{

            img{

               // box-shadow: -3px 10px 25px 0px rgba(0, 0, 0, .3);
                filter:         drop-shadow(0px 8px 15px rgba(0,47,183,0.3));

            }
        }

        .item{
            .name-tag{
                margin-top: 5px;
                p{
                    font-size: .6rem;
                    margin-bottom: 0;
                    padding-bottom: 0;
                }

                .head{
                    font-weight: bold;
                    font-size: .62rem;
                    margin-bottom: -3px;
                }
            }
        }




        /*.item {

            cursor:pointer;

            p {
                color: $primary;
                margin-bottom: 0;
                font-size: 14px;
                font-weight: 700;
            }


            .item-inner {
                background: #e2e8f6;
            }

            .item-shadow {
                box-shadow: -3px 10px 25px 0px rgba(0, 0, 0, .3);
            }


            .item-inner::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: -1;

                box-shadow: -3px 12px 35px 0px rgba(0, 0, 0, .5);
                opacity: .2;
                transition: opacity 0.3s ease-in-out;
            }

            .item-inner:hover::after {
                opacity: .6;
            }



        }
*/

    }

}
