@import '../../styles/mixins';
//@import "../../styles/styles";
@import '../../styles/color';



.influencer-testimoniel{

    //min-height: 100%;
    min-height: 100px;

    /*@include media-breakpoint-down(md) {
        min-height: 50%;
    }*/

    /*


    @include media-breakpoint-down(md) {
        height: 50%;
    }


    @include media-breakpoint-down(sm) {
        height: 25%;
    }
    */


    .content{







        @include media-breakpoint-up(sm) {

            @media (max-height: 790px) {
                //margin-bottom: 120px;
                height:600px;
            }


            //height: auto;
        }


    }


    .user-image{

        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url("../../assets/influencers/influencerQuoteImage.png");

    }


    .myquote{
        text-indent: 40px;

        svg{
            g{
                fill: chocolate;
            }

        }
    }

    .myquote:before {
        content:url("../../assets/svg/quote.svg");
        position: absolute;
        width: 30px;
        height: 30px;
        top:0;
        left:-40px;
    }




}
