@import 'color';


$font-family-base: 'PT Sans', 'Playfair Display', 'Arial',  'sans-serif';


$body-bg: $primary; //rgba(248,248,248,1);
$body-color: #111; // also font color


// BTNS
$btn-padding-y:               .7rem;
$btn-padding-x:               1.4rem;
$input-btn-border-width:      2px;
$btn-font-weight:            700;



    //$btn-font-weight:             700;

    // Grid
$grid-gutter-width: 30px; // Unchanged

$card-spacer-y: 25px;

// Navbar
$navbar-dark-color:         rgba(#fff, .7);
$navbar-dark-hover-color:   rgba(#fff, .9);
$navbar-nav-link-padding-x: 1rem;

// Modal
$modal-content-bg:     #2a333f;
$modal-backdrop-opacity: .75;
$modal-md:               600px;
$modal-transition:       transform 150ms ease-out;

// Headings
$headings-font-weight: 500;

// Fonts
$font-weight-light:  200;
$font-weight-normal: 300;
$font-weight-bold:   500;
$line-height-base:   1.4;
$font-size-base:     1rem;
$font-size-lg:       $font-size-base * 1.2;
$font-size-sm:       $font-size-base * .8;

// Colors
$theme-colors: (
        'warning': #ffb32c,
        'danger': #ff4422,
        //'primary': #0080ff,
        'primary': $blue,
        'info': #76dcf2
);

// Grid
$grid-breakpoints: ( // Unchanged
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px
);

// Carousel
$carousel-control-color:#000;
$carousel-transition:     transform .6s ease; // Unchanged


// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
// stylelint-disable-next-line scss/dollar-variable-default

$sizes: map-merge(

                (
                        5: 5%,
                        10: 10%,
                        20: 20%,
                        25: 25%,
                        30: 30%,
                        40: 40%,
                        50: 50%,
                        60: 60%,
                        75: 75%,
                        80: 80%,
                        100: 100%,
                        auto: auto
                ),
                $sizes
);
