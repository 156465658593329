@import '../../styles/variables';
@import '../../styles/color';
@import "node_modules/bootstrap/scss/mixins";


@mixin my-page-headline($color: $primary , $alignSide: center ) {

  padding-top: 1rem;
  text-align: $alignSide;

  h2 {
    color: $color;
    font-family: "Playfair Display";
  }

  p {
    color: $color;
    padding-top: 1rem;
  }



  @include media-breakpoint-down(xl) {
    padding-left:10%;
    padding-right:10%;

    h2 {
      font-size: 2.5rem;

    }
  }


  @include media-breakpoint-down(lg) {
    //background: green;
    margin-left:5%;
    margin-right:5%;

    h2 {
      font-size: 2rem;
    }
  }


  @include media-breakpoint-down(md) {

    margin-left: 0px;
    margin-right: 0px;
    padding-left:0%;
    padding-right:0%;


    p {
      margin-left: 20px;
      margin-right: 20px;
    }

    h2 {

      font-size: 2rem;
    }
  }




}
