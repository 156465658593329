

@import '../../styles/mixins';
@import '../../styles/variables';

.contact {


    .section-headline{

        @include my-section-headline();
        max-width: 1000px;

    }


    .logo {
        g {
            fill: $primary;
        }
    }
}
