/*
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;*/
/*
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;*/
.front {
  padding-top: 15px !important;
  /*.v-header{

        height: 100vh;
        //display: flex;
        //align-items: center;

    }*/
  /*
    .video-wrap:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;
        height: 100%;
        width: 100%;
        background: rgba(0, 51, 199, .3);
    }
    */ }
  .front .dot-pattern {
    position: absolute;
    top: 50px;
    right: 50px;
    background-image: url("../../assets/dot-pattern.png");
    z-index: 1000;
    width: 150px;
    height: 500px; }
    @media (max-width: 767.98px) {
      .front .dot-pattern {
        width: 60px;
        height: 100px; } }
  .front .video-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    padding-bottom: 36px; }
    .front .video-wrap video {
      min-width: 100%;
      min-height: 100%;
      box-shadow: -3px 12px 35px 0px rgba(0, 0, 0, 0.5); }
  .front .front-background {
    background-image: url("../../assets/svg/bigSwirl_min.svg");
    background-position: 80% 300%;
    background-size: auto 80%;
    background-repeat: no-repeat; }
  .front .headline {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    position: relative;
    z-index: 100; }
    .front .headline p {
      color: #0033c7; }
    .front .headline h1 {
      color: #0033c7;
      font-size: 4.2vw;
      padding-bottom: 1rem; }
    @media (max-width: 991.98px) {
      .front .headline h1 {
        font-size: 2rem; }
      .front .headline h2 {
        font-size: 2rem; } }
  .front .front-content .slide-background {
    position: absolute;
    height: auto;
    top: 0;
    left: 0;
    bottom: 0; }
    .front .front-content .slide-background .sliding-image {
      background: url("../../assets/mobile.png") repeat-x;
      background-position: 100% 100%;
      width: 5076px;
      height: 100%;
      animation: slideHoz 15s linear infinite; }
  .front .front-content .txt-content {
    width: 100%;
    width: 70%; }
    @media (max-width: 1199.98px) {
      .front .front-content .txt-content {
        width: 50%; } }
    @media (max-width: 991.98px) {
      .front .front-content .txt-content {
        width: 70%;
        padding-left: 5%; } }
    @media (max-width: 767.98px) {
      .front .front-content .txt-content {
        width: 100%;
        padding-left: 5%; } }

@keyframes slideHoz {
  0% {
    transform: translate3d(0, 0, 0); }
  100% {
    transform: translate3d(-1692px, 0, 0); } }
