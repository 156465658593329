
@import '../../styles/mixins';

.influencers-top {


    .headline {
        @include my-page-headline(white, center);
    }

    .bg-image{
        background-image: url('../../assets/influencers/topback.jpg');
        background-repeat: no-repeat;
        background-position: left;
        //background-size: 291px 100%;
        background-size: cover; //auto 100%;
    }


    .head-image {

        //width:291px;

        //background-image: url('../../assets/influencers/topback.jpg');
        background-repeat: no-repeat;
        background-position: left;
        //background-size: 291px 100%;
        background-size: cover; //auto 100%;
        //background-size: cover;


    }

}
