@import '../../styles/color';

.maria-black-case {


    //height: auto;

    h5 {
        color: $primary;
    }

    p {
        color: $primary;
    }


    .icon-box {


        width:100px;
        height:90px;

        .mySvgIcon {
            path:nth-child(2) {
                fill: $primary;
            }
        }


        .mySvgIcon {
            width: 48px;
            height: 48px;
        }


        .box-info {

            p {
                margin: 0;
                font-size: 12px;
                font-weight: 700;
            }

            h6 {

                font-size: 14px;
            }

        }


    }


}
