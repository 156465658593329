.app{
   position: relative;
   height: 100%;

  // overflow-x: hidden;
}


.container-fluid{
   height: 100%;
}


.app-wrapper {

   //overflow: hidden;
   //overflow-x: hidden;
   //overflow-y: scroll;


   .app{
      position: relative;
      height: 100%;
   }

   .app-animated-container{
      position: absolute;
      height:100%;
      width:100%;
      //overflow: hidden;
   }

}



