html, body {
    //margin: 0;
    //padding: 0;
    //font-family: sans-serif;
    height: 100%;

    -webkit-overflow-scrolling: touch;


}

body{
    // this solve the bug - which causes the scroll event to never fire
    // the overflow must be placed on on the body
    overflow-x: hidden;
}


#root {
    //overflow-y: scroll;
    //overflow-x: hidden;
    height: 100%;
    //background: chocolate;
}
