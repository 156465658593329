/*
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;*/
/*
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;*/
.app-menu-overlay {
  background: rgba(0, 0, 0, 0.85);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  right: 0;
  z-index: 200; }
  @media (min-width: 768px) {
    .app-menu-overlay .menu-items {
      margin-top: 100px; } }
  .app-menu-overlay .menu-items .menu-item {
    cursor: pointer;
    text-align: left;
    position: relative;
    /* span{
        position: absolute;
        top:50%;
        left:0px;
        width:60px;
        height: 2px;
        background: white;
        opacity: 0;
      }*/ }
    .app-menu-overlay .menu-items .menu-item .lineLeft {
      position: absolute;
      top: 50%;
      left: 0;
      width: 40px;
      height: 2px;
      background: white;
      opacity: 0; }
      @media (max-width: 991.98px) {
        .app-menu-overlay .menu-items .menu-item .lineLeft {
          width: 15%; } }
    .app-menu-overlay .menu-items .menu-item .lineRight {
      position: absolute;
      top: 50%;
      right: 0;
      width: 0%;
      height: 2px;
      background: white;
      opacity: 0;
      transition: opacity 0.5s ease-out; }
      @media (max-width: 991.98px) {
        .app-menu-overlay .menu-items .menu-item .lineRight {
          width: 15%; } }
    .app-menu-overlay .menu-items .menu-item a.active .lineLeft {
      opacity: 1; }
    .app-menu-overlay .menu-items .menu-item a.active .lineRight {
      opacity: 1; }
    .app-menu-overlay .menu-items .menu-item a:hover {
      color: white;
      height: 1px; }
    .app-menu-overlay .menu-items .menu-item h1 {
      font-size: 4vw;
      color: white;
      padding: 4px;
      margin-left: 5%; }
    @media (max-width: 991.98px) {
      .app-menu-overlay .menu-items .menu-item {
        text-align: center; }
        .app-menu-overlay .menu-items .menu-item h1 {
          font-size: 40px;
          color: white;
          padding: 20px;
          margin-left: 0; } }
