@import '../../styles/variables';
@import "node_modules/bootstrap/scss/mixins";

@mixin my-section-headline( $alignSide: left ) {

  //padding-top: 1rem;
  text-align: $alignSide;

  h1 {
    color: $primary;
    font-family: "Playfair Display";
  }


  p {
    color: $primary;
    font-size: 1.2rem;
  }




  @include media-breakpoint-down(xl) {

    h2 {
      font-size: 2.5rem;

    }
  }


  @include media-breakpoint-down(lg) {

    h2 {
      font-size: 2rem;
    }
    p {
      font-size: 1rem;
    }

  }

    @include media-breakpoint-down(md) {

      h2 {

        font-size: 2rem;
      }

      p {
        font-size: 1rem;
      }

    }




}
